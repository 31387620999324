import { ASSET_CDN_URL } from '../../../../Constants';
import { DEFAULT_BATCH_OPTIONS } from './messaging/actions/broadcast/BroadcastConstants';

const smsSelectedChannelIcon = `${ASSET_CDN_URL}/icons/sms-channel-selected.svg`;
const smsUnselectedChannelIcon = `${ASSET_CDN_URL}/icons/sms-channel-unselected.svg`;
const whatsappSelectedChannelIcon = `${ASSET_CDN_URL}/icons/whatsapp-channel-selected.svg`;
const whatsappUnselectedChannelIcon = `${ASSET_CDN_URL}/icons/whatsapp-channel-unselected.svg`;

const messagingNavigationSearchIcon = `${ASSET_CDN_URL}/icons/rex-messaging-navigation-search-icon.svg`;
const messagingNavigationBroadcastIcon = `${ASSET_CDN_URL}/icons/rex-messaging-navigation-broadcast-icon.svg`;
const messagingNavigationTasksIcon = `${ASSET_CDN_URL}/icons/rex-messaging-navigation-tasks-icon.svg`;
const messagingNavigationInboxIcon = `${ASSET_CDN_URL}/icons/rex-messaging-navigation-inbox-icon.svg`;
const messagingNavigationCustomersIcon = `${ASSET_CDN_URL}/icons/rex-messaging-navigation-customers-icon.svg`;
const messagingNavigationEmployeesIcon = `${ASSET_CDN_URL}/icons/rex-messaging-navigation-employees-icon.svg`;

const interactionAppointmentIcon = `${ASSET_CDN_URL}/icons/rex-interaction-appointment-icon.svg`;
const interactionSMSIcon = `${ASSET_CDN_URL}/icons/rex-interaction-sms-icon.svg`;
const interactionEmailIcon = `${ASSET_CDN_URL}/icons/rex-interaction-email-icon.svg`;
const interactionPhoneIcon = `${ASSET_CDN_URL}/icons/rex-interaction-phone-icon.svg`;

const BIRD_WORKREX_ADMINS = ['6597c95954aac61a76c64fe7', '663b88cf15b27c4591b609a7', '661fc98bb1fd071aefcef4b1'];
const BIRD_BUSINESS_CUSTOMERS = ['6586d5cb077a408d4e12b526','65acc9b3786f007f96f98831','65b885c1fbd2bc66e012956d','65cabac068d3f52a748e7a31','65cea8800cebfb1a77aec792','65d443264611b391f5fc157e','65e14caba3d06374748d0b73','65ea8558dacfd972811ccb9f','65f961552ed8ee44ec14bd0c','6614d99dbbd9f7269a9e2031','662f2657c00abd66679ca540','662f2e0bee9322768c1b64bf','666a602ad530e1858e6999b2'];
export const ALL_BIRD_BUSINESSES = [ ...BIRD_WORKREX_ADMINS, ...BIRD_BUSINESS_CUSTOMERS ];

export const CHANNELS = {
  SMS: {
    key: 'SMS',
    displayText: 'SMS',
    selectedIcon: smsSelectedChannelIcon,
    unselectedIcon: smsUnselectedChannelIcon
  },
  WHATSAPP: {
    key: 'WHATSAPP',
    displayText: 'Whatsapp',
    selectedIcon: whatsappSelectedChannelIcon,
    unselectedIcon: whatsappUnselectedChannelIcon
  },
};

export const CONTACT_DETAIL_DISPLAY_FIELDS = {
  firstName: {
    displayText: 'First name',
    key: 'firstName',
    dataType: 'STRING'
  },
  lastName: {
    displayText: 'Last name',
    key: 'lastName',
    dataType: 'STRING'
  },
  emailAddress: {
    displayText: 'Email',
    key: 'emailAddress',
    dataType: 'STRING'
  },
  mobileNumber: {
    displayText: 'Contact number',
    key: 'mobileNumber',
    dataType: 'STRING'
  },
  birthday: {
    displayText: 'Birthday',
    key: 'birthday',
    dataType: 'DATE'
  },
  age: {
    displayText: 'Age',
    key: 'age',
    dataType: 'STRING'
  },
  yearsActive: {
    displayText: 'Years Active',
    key: 'yearsActive',
    dataType: 'STRING'
  },
  averageTransactionValue: {
    displayText: 'Average Transaction Value',
    key: 'averageTransactionValue',
    dataType: 'STRING'
  },
  address: {
    displayText: 'Address',
    key: 'address',
    dataType: 'STRING'
  },
  source: {
    displayText: 'Source',
    key: 'source',
    dataType: 'STRING'
  },
  favouriteBrands: {
    displayText: 'Favourite Brands',
    key: 'favouriteBrands',
    dataType: 'STRING'
  },
  customerSegmentCategory: {
    displayText: 'Customer Segment Category',
    key: 'customerSegmentCategory',
    dataType: 'STRING'
  },
  lifeTimeValue: {
    displayText: 'Lifetime Value',
    key: 'lifeTimeValue',
    dataType: 'STRING'
  },
};

export const CONTACT_TYPES = {
  CUSTOMER: {
    key: 'CUSTOMER',
    text: 'Customer',
  },
  EMPLOYEE: {
    key: 'EMPLOYEE',
    text: 'Employee',
  },
  ADMIN: {
    key: 'ADMIN',
    text: 'Admin',
  },
};

export const FILE_TYPES = {
  JPEG: {
    key: 'JPEG',
    format: '.jpeg'
  },
  PNG: {
    key: 'PNG',
    format: '.png'
  },
  MP4: {
    key: 'MP4',
    format: '.mp4'
  },
  PDF: {
    key: 'PDF',
    format: '.pdf'
  },
}

export const DEFAULT_BROADCAST = {
  broadcastName: 'Custom Broadcast',
  recipientIds: [],
  message: '',
  campaignId: null,
  templateId: null,
  nextBroadcastDate: new Date().setHours(9, 0, 0, 0),
  firstSendDate: null,
  firstSend: false,
  lastSendDate: null,
  frequency: 'NONE',
  deletionDate: null,
  createdById: null,
  channel: null,
  paused: false,
  groupId: null,
  businessId: null,
  attachment: null,
  createMethod: 'BROADCAST',
  batchOptions: DEFAULT_BATCH_OPTIONS,
  variantAssignments: {},
};

export const DEFAULT_INTERACTION = {
  name: '',
  type: '',
  lastModifiedDate: '',
  businessId: '',
  sender: '',
  receiver: '',
  createdBy: '',
  description: '',
  startDateTime: Date.now(),
  endDateTime: Date.now(),
  groupId: '',
  externalGroupId: '',
  externalSystemId: '',
  archived: false,
};

export const DEFAULT_TASK = {
  label: '',
  assignedTo: '',
  startDate: null,
  endDate: null,
  dueDate: null,
  taggedTo: '',
  frequency: 'NONE',
  completed: false,
  createdBy: '',
  archived: false,
};

export const STAGE_MAPPING = {
  'REQUESTED': {
    key: 'REQUESTED',
    text: 'Requested',
    value: 0,
  },
  'DRAFT': {
    key: 'DRAFT',
    text: 'Draft',
    value: 1,
  },
  'SUBMITTED': {
    key: 'SUBMITTED',
    text: 'Submitted',
    value: 2,
  },
  'PAUSED': {
    key: 'PAUSED',
    text: 'Paused',
    value: 3,
  },
  'LIVE': {
    key: 'LIVE',
    text: 'Live',
    value: 4,
  },
}

export const DATA_SOURCE_MAPPING = {
  FIRST_NAME: {
    key: 'FIRST_NAME',
    text: 'First name',
  },
  LAST_NAME: {
    key: 'LAST_NAME',
    text: 'Last name',
  },
  DATE_OF_BIRTH: {
    key: 'DATE_OF_BIRTH',
    text: 'Date of birth',
  }
}

export const ACTION_VIEWS = {
  MESSAGES: {
    key: 'MESSAGES',
    text: 'Messages',
    buttonText: 'messages'
  },
  INTERACTIONS: {
    key: 'INTERACTIONS',
    text: 'Interactions',
    buttonText: 'interaction'
  },
  TASKS: {
    key: 'TASKS',
    text: 'Tasks',
    buttonText: 'tasks'
  },
}

export const MESSAGING_VIEWS = {
  SEARCH: {
    key: 'SEARCH',
    text: 'Search',
    url: '/messaging/search',
    defaultFilter: '',
    defaultURL: '/messaging/search',
    title: '',
    filters: {},
    icon: messagingNavigationSearchIcon
  },
  BROADCAST: {
    key: 'BROADCAST',
    text: 'Broadcast',
    url: '/messaging/broadcasts',
    defaultFilter: 'NEW',
    defaultURL: '/messaging/broadcasts/new',
    title: '',
    filters: {
      NEW: {
        key: 'NEW',
        text: 'Add new',
        url: '/messaging/broadcasts/new',
      },
      UPCOMING: {
        key: 'UPCOMING',
        text: 'Upcoming',
        url: '/messaging/broadcasts/upcoming',
      }
    },
    icon: messagingNavigationBroadcastIcon
  },
  TASKS: {
    key: 'TASKS',
    text: 'Tasks',
    url: '/messaging/tasks',
    defaultFilter: 'ALL',
    defaultURL: '/messaging/tasks/all',
    title: '',
    filters: {
      OUTSTANDING: {
        key: 'OUTSTANDING',
        text: 'Outstanding',
        url: '/messaging/tasks/outstanding',
      },
      COMPLETED: {
        key: 'COMPLETED',
        text: 'Completed',
        url: '/messaging/tasks/completed',
      },
      ALL: {
        key: 'ALL',
        text: 'All tasks',
        url: '/messaging/tasks/all',
      }
    },
    icon: messagingNavigationTasksIcon
  },
  INBOX: {
    key: 'INBOX',
    text: 'Inbox',
    url: '/messaging/inbox',
    defaultFilter: 'ALL',
    defaultURL: '/messaging/inbox/all',
    title: '',
    filters: {
      UNREAD: {
        key: 'UNREAD',
        text: 'Unread',
        url: '/messaging/inbox/unread',
      },
      ALL: {
        key: 'ALL',
        text: 'All messages',
        url: '/messaging/inbox/all',
      }
    },
    icon: messagingNavigationInboxIcon
  },
  CUSTOMERS: {
    key: 'CUSTOMERS',
    text: 'Customers',
    url: '/messaging/customers',
    defaultFilter: 'ALL',
    defaultURL: '/messaging/customers/all',
    title: 'Shared with team',
    filters: {
      ALL: {
        key: 'ALL',
        text: 'All customers',
        url: '/messaging/customers/all',
      },
      ACTIONS_REQUIRED: {
        key: 'ACTIONS_REQUIRED',
        text: 'Actions required',
        url: '/messaging/customers/actions',
      }
    },
    icon: messagingNavigationCustomersIcon
  },
  EMPLOYEES: {
    key: 'EMPLOYEES',
    text: 'Employees',
    url: '/messaging/employees',
    defaultFilter: 'ALL',
    defaultURL: '/messaging/employees/all',
    title: 'Admins only',
    filters: {
      ALL: {
        key: 'ALL',
        text: 'All employees',
        url: '/messaging/employees/all',
      },
      ACTIONS_REQUIRED: {
        key: 'ACTIONS_REQUIRED',
        text: 'Actions required',
        url: '/messaging/employees/actions',
      }
    },
    icon: messagingNavigationEmployeesIcon
  },
}

export const VIEW_PATHNAMES = Object.keys(MESSAGING_VIEWS).reduce((acc, view) => ({ ...acc, [MESSAGING_VIEWS[view].url]: MESSAGING_VIEWS[view].key}), {});

export const INTERACTION_TYPE_MAPPING = {
  APPOINTMENT: {
    key: 'APPOINTMENT',
    text: 'Appointment',
    icon: interactionAppointmentIcon,
  },
  MEETING: {
    key: 'MEETING',
    text: 'Meeting',
    icon: interactionAppointmentIcon,
  },
  TRANSACTION: {
    key: 'TRANSACTION',
    text: 'Transaction',
    icon: interactionAppointmentIcon,
  },
  CAMPAIGN: {
    key: 'CAMPAIGN',
    text: 'Campaign',
    icon: interactionAppointmentIcon,
  },
  PHONE: {
    key: 'PHONE',
    text: 'Phone call',
    icon: interactionPhoneIcon,
  },
  WHATSAPP_SMS: {
    key: 'WHATSAPP_SMS',
    text: 'WhatsApp / SMS',
    icon: interactionSMSIcon,
  },
  EMAIL: {
    key: 'EMAIL',
    text: 'Email',
    icon: interactionEmailIcon,
  },
  OTHER: {
    key: 'OTHER',
    text: 'Other',
    icon: interactionAppointmentIcon,
  },
  VISIT: {
    key: 'VISIT',
    text: 'Visit',
    icon: interactionAppointmentIcon,
  },
  PURCHASE: {
    key: 'PURCHASE',
    text: 'Purchase',
    icon: interactionAppointmentIcon,
  },
};

export const REPEAT_MAP = {
  NONE: {
    key: 'NONE',
    text: 'Not repeating',
    broadcastText: 'Does not recur',
  },
  WEEKLY: {
    key: 'WEEKLY',
    text: 'Weekly',
    broadcastText: 'Weekly',
  },
  FORTNIGHTLY: {
    key: 'FORTNIGHTLY',
    text: 'Fortnightly',
    broadcastText: 'Fortnightly',
  },
  MONTHLY: {
    key: 'MONTHLY',
    text: 'Monthly',
    broadcastText: 'Monthly',
  },
  QUARTERLY: {
    key: 'QUARTERLY',
    text: 'Quarterly',
    broadcastText: 'Quarterly',
  },
  BI_ANNUAL: {
    key: 'BI_ANNUAL',
    text: '6 moonths',
    broadcastText: '6 moonths',
  },
  YEARLY: {
    key: 'YEARLY',
    text: 'Yearly',
    broadcastText: 'Yearly',
  },
}

export const BROADCAST_REPEAT_MAP = {
  NONE: {
    key: 'NONE',
    text: 'Does not recur',
    dropdownText: 'Do not repeat'
  },
  WEEKLY: {
    key: 'WEEKLY',
    text: 'Recurs weekly',
    dropdownText: 'Repeat weekly'
  },
  FORTNIGHTLY: {
    key: 'FORTNIGHTLY',
    text: 'Recurs fortnightly',
    dropdownText: 'Repeat fortnightly'
  },
  MONTHLY: {
    key: 'MONTHLY',
    text: 'Recurs monthly',
    dropdownText: 'Repeat monthly'
  },
  QUARTERLY: {
    key: 'QUARTERLY',
    text: 'Recurs quarterly',
    dropdownText: 'Repeat quarterly'
  },
  BI_ANNUAL: {
    key: 'BI_ANNUAL',
    text: 'Recurs biannually',
    dropdownText: 'Repeat biannually'
  },
  YEARLY: {
    key: 'YEARLY',
    text: 'Recurs yearly',
    dropdownText: 'Repeat yearly'
  },
}

export const RECORD_TYPES = {
  BROADCAST: {
    key: 'BROADCAST',
    text: 'Broadcast',
  },
  CHAT: {
    key: 'CHAT',
    text: 'Chat',
  },
  CUSTOMER: {
    key: 'CUSTOMER',
    text: 'Customer',
  },
  EMPLOYEE: {
    key: 'EMPLOYEE',
    text: 'Employee',
  },
  ADMIN: {
    key: 'ADMIN',
    text: 'Admin',
  },
  INTERACTION: {
    key: 'INTERACTION',
    text: 'Interaction',
  },
  TASK: {
    key: 'TASK',
    text: 'Task',
  },
}

export const BROADCAST_CONTACTS_FILTER_OPTIONS = {
  ALL: {
    key: 'ALL',
    text: 'Show all',
  },
  CUSTOMERS: {
    key: 'CUSTOMERS',
    text: 'All customers',
  },
  EMPLOYEES: {
    key: 'EMPLOYEES',
    text: 'All employees',
  },
}

export const HOUR_MAP = {
  1: {
    key: 1,
    value: 1,
    text: '01',
  },
  2: {
    key: 2,
    value: 2,
    text: '02',
  },
  3: {
    key: 3,
    value: 3,
    text: '03',
  },
  4: {
    key: 4,
    value: 4,
    text: '04',
  },
  5: {
    key: 5,
    value: 5,
    text: '05',
  },
  6: {
    key: 6,
    value: 6,
    text: '06',
  },
  7: {
    key: 7,
    value: 7,
    text: '07',
  },
  8: {
    key: 8,
    value: 8,
    text: '08',
  },
  9: {
    key: 9,
    value: 9,
    text: '09',
  },
  10: {
    key: 10,
    value: 10,
    text: '10',
  },
  11: {
    key: 11,
    value: 11,
    text: '11',
  },
  12: {
    key: 12,
    value: 0,
    text: '12',
  }
}

export const MINUTE_MAP = {
  0: {
    key: 0,
    text: '00',
  },
  5: {
    key: 5,
    text: '05',
  },
  10: {
    key: 10,
    text: '10',
  },
  15: {
    key: 15,
    text: '15',
  },
  20: {
    key: 20,
    text: '20',
  },
  25: {
    key: 25,
    text: '25',
  },
  30: {
    key: 30,
    text: '30',
  },
  35: {
    key: 35,
    text: '35',
  },
  40: {
    key: 40,
    text: '40',
  },
  45: {
    key: 45,
    text: '45',
  },
  50: {
    key: 50,
    text: '50',
  },
  55: {
    key: 55,
    text: '55',
  }
}