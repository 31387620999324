import './ChannelDropdown.scss';

import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { CHANNELS } from '../../../../../MessagingConstants';

import RIGHT_CARET_ICON from '../../../../../../common/assets/right-caret-icon.svg';

import ChannelDropdownOption from './ChannelDropdownOption';

const propTypes = {};

function ChannelDropdown({ dropdownSelected, dropdownOptions, text, setSelectedData }) {
  const [open, setOpen] = useState(false);
  console.log(dropdownOptions);
  const toggle = useCallback((select) => {
    const wrapper = document.getElementById(`channel-dropdown-wrapper`);
    const dropdownText = document.getElementById(`channel-dropdown-list`);
    const header = document.getElementById('channel-dropdown-header');

    if (open !== null) {
      setOpen(!select);
    }

    wrapper.style.height = select ? '0px' : `${dropdownText.clientHeight}px`;
    wrapper.style.maxHeight = select ? '0px' : `${dropdownText.clientHeight}px`;
    wrapper.style.bottom = `${header.clientHeight + 1}px`;
  }, [open, text])

  const setSelected = (option) => {
    setSelectedData(option);
    toggle(open);
  }

  useEffect(() => {
    if (open) {
      toggle(null)
    }
  }, [open, toggle])

  return (
    <section className={'channel-dropdown-container'}>
      <section id={'channel-dropdown-header'} className={'channel-dropdown-header'} onClick={() => toggle(open)}>
        <section className={'channel-details'}>
          <img alt={`${CHANNELS[dropdownSelected[0]].displayText}-icon`} className={'channel-icon'} src={CHANNELS[dropdownSelected[0]].unselectedIcon} />
          <span className={'channel-dropdown-title'} >{dropdownOptions[text].displayText}</span>
        </section>
        <img src={RIGHT_CARET_ICON} alt={''} className={`rec-header-icon ${open ? 'open' : ''}`} />
      </section>
      <section id={`channel-dropdown-wrapper`} className={'channel-dropdown-content'}>
        <section id={`channel-dropdown-list`} className={'channel-dropdown-answer'}>
          {Object.keys(dropdownOptions).map((option) => <ChannelDropdownOption option={dropdownOptions[option]} selected={dropdownSelected} setSelected={setSelected} key={option} />)}
        </section>
      </section>
    </section>
  )
}

ChannelDropdown.displayText = 'ChannelDropdown';
ChannelDropdown.propTypes = propTypes;
export default ChannelDropdown;
