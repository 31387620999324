import {
  computeAge,
  computeAveragePurchaseFrequency,
  computeAverageTransactionValue,
  computeEmployeeManagerName,
  computeFirstOrLastTransactionDate,
  computeInteractionTypeCount,
  computeLastAppointmentDate,
  computeLastInteractionDate,
  computeLastCampaignDate,
  computeLifetimeValue,
  computeYearsActive
} from "../../../utils/FormulaFieldUtils";
import { INTERACTION_TYPE_MAPPING } from "./communications/MessagingConstants";

const INTERACTION_TYPES = Object.keys(INTERACTION_TYPE_MAPPING);
const { APPOINTMENT, TRANSACTION } = INTERACTION_TYPE_MAPPING;

export const DATE_FIELDS = [
  'firstTransactionDate',
  'lastInteractionDate',
  'lastTransactionDate',
  'lastAppointmentDate',
  'lastCampaignDate',
]

export const DERIVED_FIELDS = [
  // {
  //   fieldName: 'employeeManagerFirstName',
  //   formulaFunction: computeEmployeeManagerName('firstName'),
  //   dependencies: [
  //     'managers'
  //   ]
  // },
  // {
  //   fieldName: 'employeeManagerLastName',
  //   formulaFunction: computeEmployeeManagerName('lastName'),
  //   dependencies: [
  //     'managers'
  //   ]
  // },
  // {
  //   fieldName: 'employeeManagerFullName',
  //   formulaFunction: computeEmployeeManagerName('fullName'),
  //   dependencies: [
  //     'managers'
  //   ]
  // },
  {
    displayName: 'Last Interaction Date',
    fieldName: 'lastInteractionDate',
    formulaFunction: computeLastInteractionDate,
    dependencies: [
      'interactions'
    ]
  },
  {
    displayName: 'Last Campaign Date',
    fieldName: 'lastCampaignDate',
    formulaFunction: computeLastCampaignDate,
    dependencies: [
      'broadcasts'
    ]
  },
  {
    displayName: 'Last Appointment Date',
    fieldName: 'lastAppointmentDate',
    formulaFunction: computeLastAppointmentDate,
    dependencies: [
      'interactions'
    ]
  },
  {
    displayName: 'Interaction Count',
    fieldName: 'countInteractions',
    formulaFunction: computeInteractionTypeCount(INTERACTION_TYPES),
    dependencies: [
      'interactions'
    ]
  },
  {
    displayName: 'Appointment Count',
    fieldName: 'countAppointments',
    formulaFunction: computeInteractionTypeCount([APPOINTMENT.key]),
    dependencies: [
      'interactions'
    ]
  },
  {
    displayName: 'Transaction Count',
    fieldName: 'countTransactions',
    formulaFunction: computeInteractionTypeCount([TRANSACTION.key]),
    dependencies: [
      'interactions'
    ]
  },
  {
    displayName: 'First Transaction Date',
    fieldName: 'firstTransactionDate',
    formulaFunction: computeFirstOrLastTransactionDate('first'),
    dependencies: [
      'interactions'
    ]
  },
  {
    displayName: 'Last Transaction Date',
    fieldName: 'lastTransactionDate',
    formulaFunction: computeFirstOrLastTransactionDate('last'),
    dependencies: [
      'interactions'
    ]
  },
  {
    displayName: 'Lifetime Value',
    fieldName: 'lifeTimeValue',
    formulaFunction: computeLifetimeValue,
    dependencies: [
      'interactions'
    ]
  },
  {
    displayName: 'Average Transaction Value',
    fieldName: 'averageTransactionValue',
    formulaFunction: computeAverageTransactionValue,
    dependencies: [
      'interactions'
    ]
  },
  {
    displayName: 'Average Purchase Frequency',
    fieldName: 'averagePurchaseFrequency',
    formulaFunction: computeAveragePurchaseFrequency,
    dependencies: [
      'interactions'
    ]
  },
  {
    displayName: 'Age',
    fieldName: 'age',
    formulaFunction: computeAge,
    dependencies: [
      'birthday'
    ]
  },
  // {
  //   fieldName: 'referralCode',
  //   formulaFunction: '',
  // },
  // {
  //   fieldName: 'sentiment',
  //   formulaFunction: '',
  // },
  {
    displayName: 'Years Active',
    fieldName: 'yearsActive',
    formulaFunction: computeYearsActive,
    dependencies: [
      'interactions'
    ]
  },
  // {
  //   fieldName: 'favouritePromotions',
  //   formulaFunction: '',
  // },
  // {
  //   fieldName: 'profileSummary',
  //   formulaFunction: '',
  // },
  // {
  //   fieldName: 'nextPurchaseDate',
  //   formulaFunction: '',
  // },
];