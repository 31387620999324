import './NewBroadcastPage.scss';

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { postCreateBroadcastScheduler } from '../../../../../../../apis/BroadcastApi';
import MessagingConsumer from '../../../../../../../context/MessagingContext';
import useAuth from '../../../../../../../context/AuthContext';
import { CHANNELS, CONTACT_TYPES, DEFAULT_BROADCAST, MESSAGING_VIEWS } from '../../../MessagingConstants';
import { DELIVERABILITY } from '../../../../recipients/RecipientsConstants';

import DotBricksLoading from '../../../../../../common/DotBricksLoading';
import ConfirmBroadcastModal from './modal/ConfirmBroadcastModal';
import TemplateTextInput from '../../../templates/common/TemplateTextInput';
import AdditionalBroadcastButtons from './AdditionalBroadcastButtons';
import ChannelSelectionOptions from '../../../campaigns/common/ChannelSelectionOptions';
import { AVAILABLE_CHANNEL_LIST, CAMPAIGN_PAGE_TYPES } from '../../../campaigns/CampaignConstants';

import AttachmentModal from '../../../campaigns/modals/AttachmentModal';
import CampaignSelectionModal from './modal/CampaignSelectionModal';
import Attachment from '../../../campaigns/previews/common/Attachment';
import BatchSelectionModal from './modal/BatchSelectionModal';
import ScheduleSelectionModal from './modal/ScheduleSelectionModal';

const { PUBLISHED } = CAMPAIGN_PAGE_TYPES;

const { SUBSCRIBED } = DELIVERABILITY;
const { BROADCAST } = MESSAGING_VIEWS;
const { ADMIN } = CONTACT_TYPES;
const maxCharCount = 800;

const propTypes = {};

function assignVariantsToRecipients(recipientIds, numVariants) {
  let counter = 0;
  const variantAssignments = {};
  recipientIds.forEach(recipientId => {
    variantAssignments[recipientId] = counter;
    counter = (counter + 1) % numVariants;
  });
  return variantAssignments;
};

function NewBroadcastPage({ broadcastList, setViewFilters, setRecord, setSelected }) {
  const { state } = useLocation();
  console.log({ state });
  const { user, setUser } = useAuth();
  const { campaigns } = user;
  const navigate = useNavigate();
  const stateBroadcast = state && state.broadcast ? { ...state.broadcast } : { ...DEFAULT_BROADCAST };
  const stateVariantAssignments = state && state.variantAssignments ? { ...state.variantAssignments } : {};
  const stateIncludeStopMessage = state && state.includeStopMessage ? state.includeStopMessage : true;
  const { contacts, selectedGroup, setBroadcasts, templates } = MessagingConsumer();
  const [broadcast, setBroadcast] = useState(stateBroadcast);
  const [variantAssignments, setVariantAssignments] = useState(stateVariantAssignments);
  const [includeStopMessage, setIncludeStopMessage] = useState(stateIncludeStopMessage);

  const { campaignId, batchOptions, attachment, channel, nextBroadcastDate, message } = broadcast;
  const [scheduleModalOpen, setScheduleModalOpen] = useState(false);
  const [batchSelectionModalOpen, setBatchSelectionModalOpen] = useState(false);
  const [campaignSelectionModalOpen, setCampaignSelectionModalOpen] = useState(false);
  const [attachmentModalOpen, setAttachmentModalOpen] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const openBatchModal = () => setBatchSelectionModalOpen(true);
  const openScheduleModal = () => setScheduleModalOpen(true);
  const closeAttachmentModal = () => setAttachmentModalOpen(false);
  const closeBatchModal = () => setBatchSelectionModalOpen(false);
  const closeCampaignSelectionModal = () => setCampaignSelectionModalOpen(false);
  const closeScheduleModal = () => setScheduleModalOpen(false);

  const setBatchOptions = (batchOptions) => setBroadcast({ ...broadcast, batchOptions });
  const setChannel = (channels) => setBroadcast({ ...broadcast, channel: channels.length > 0 ? channels[0] : null });
  const setCampaignId = (campaignId) => setBroadcast({ ...broadcast, campaignId });
  const setAttachment = (attachment) => setBroadcast({ ...broadcast, attachment });
  const setNextBroadcastDate = (date) => setBroadcast({ ...broadcast, nextBroadcastDate: date });

  const openConfirmModal = () => setShowConfirmModal(true);
  const closeConfirmModal = () => setShowConfirmModal(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (contacts !== null) {
      let variantAssignments = {};
      if (campaignId && channel) {
        const campaign = campaigns.find(({ _id }) => _id === campaignId);
        const { channelOutputs } = campaign;
        const numVariants = channelOutputs[channel].length;
        const recipientIds = contacts
          .filter(contact => broadcastList.includes(contact._id))
          .map(({ _id }) => _id);
        variantAssignments = assignVariantsToRecipients(recipientIds, numVariants);
      }
      setVariantAssignments(variantAssignments);
      setError('');
    }
  }, [broadcastList, contacts, campaignId, channel, campaigns]);

  const removeCampaign = () => setBroadcast({ ...broadcast, campaignId: null });

  const reviewNewBroadcast = () => {
    if (broadcast && broadcastList.length === 0) {
      setError('Please select a recipient for this broadcast');
      return;
    }
    if (broadcast && !broadcast.message && !broadcast.templateId) {
      setError('Please enter a message or select a template for this broadcast');
      return;
    }
    if (broadcast && broadcast.channels.length === 0) {
      setError('Please select a channel for this broadcast.')
      return;
    }
    if (broadcast && broadcast.channels.length > 0) {
      const availableChannelsToBusiness = Object.keys(user.business.channels).filter(channel => user.business.channels[channel].length > 0);
      if (!broadcast.channels.every(requestedChannel => availableChannelsToBusiness.includes(requestedChannel.toUpperCase()))) {
        const formattedAvailableChannels = availableChannelsToBusiness.map(availableChannel => CHANNELS[availableChannel].displayText);
        const noAvailableChannels = formattedAvailableChannels.length === 0;
        const errorText = noAvailableChannels
          ? 'No channels have been set up for your business. Please reach out to our support team to set up a channel to begin broadcasting.'
          : `Only the ${formattedAvailableChannels.join(', ')} channel${formattedAvailableChannels.length === 1 ? ' has' : 's have'} been set up for your business. Reach out to our support team to request setting up a new channel.`;
        setError(errorText);
        return;
      }
    }
    openConfirmModal();
  }

  const updateMessage = (message) => {
    setBroadcast({ ...broadcast, message, templateId: '' });
    setError('');
  }
  const confirmBroadcast = () => {
    const recipients = contacts.filter(contact => broadcastList.includes(contact._id));
    const recipientsToRemove = recipients
      .filter(recipient => !recipient.mobileNumber || (recipient.contactType !== ADMIN.key && recipient.deliverability !== SUBSCRIBED.key))
      .map(recipient => recipient._id);
    const viableRecipients = broadcastList.filter(recipient => !recipientsToRemove.includes(recipient));
    const { batchOptions, channel, campaignId, message } = broadcast;
    const campaign = campaigns.find(({ _id }) => _id === campaignId);
    const messageVariants = campaign ? campaign.channelOutputs[channel].map(({ message }) => message) : [message];
    const numVariants = messageVariants.length;
    const { batchSize, batchFrequency, recurrence } = batchOptions;
    const variantAssignments = assignVariantsToRecipients(viableRecipients, numVariants);
    const broadcastScheduler = {
      channel,
      campaignId,
      variantAssignments,
      recipientIds: viableRecipients,
      groupId: selectedGroup._id,
      messageVariants,
      message,
      batchOptions: { batchSize, batchFrequency },
      recurrence,
      scheduledCreationDate: nextBroadcastDate,
      includeStopMessage,
    };
    postCreateBroadcastScheduler(broadcastScheduler)
      .then(response => {
        setUser({ ...user, broadcasts: [ ...user.broadcasts, { ...response.broadcast } ]});
        setViewFilters(BROADCAST.filters.UPCOMING.key);
        setRecord({ ...response.broadcast, recipients });
      });
    

    /**
    postBroadcastCreate({ ...broadcast, recipientIds: viableRecipients }, user._id, selectedGroup._id)
      .then(response => {
        setBroadcasts(prevState => ([ ...prevState, { ...response.broadcast } ]));
        setViewFilters(BROADCAST.filters.UPCOMING.key);
        setRecord({ ...response.broadcast, recipients });
      })
    */
    closeConfirmModal();
  }

  if (contacts === null || templates === null) {
    return <DotBricksLoading isFixed={false} />
  }

  const recipients = contacts.filter(contact => broadcastList.includes(contact._id));
  const numRecipients = recipients.length;
  const recipientsText = numRecipients === 1 ? '1 recipient' : `${numRecipients} recipients`;

  const additionalButtons = (
    <AdditionalBroadcastButtons
      onAttach={() => setAttachmentModalOpen(true)}
      onSelectCampaign={() => setCampaignSelectionModalOpen(true)}
      hideAttached={campaignId !== null}
      hideLibrary={true}
    />
  );
  const campaignOptions = campaigns
    .filter(({ type }) => type === PUBLISHED)
    .map(({ _id, name }) => ({ key: _id, displayText: name }));
  const selectedCampaignOption = campaignOptions.find(({ key }) => key === campaignId);
  const campaignName = selectedCampaignOption ? selectedCampaignOption.displayText : null;
  const previewDisabled = numRecipients === 0 || (broadcast.message === '' && broadcast.campaignId === null) || channel === null;
  const campaign = campaigns.find(({ _id }) => _id === campaignId);
  const viewOrEdit = () => {
    navigate(
      '/campaigns/recipients',
      { state: { recipients: recipients.map(({ _id }) => _id), broadcast, allRecipients: contacts, variantAssignments, includeStopMessage } }
    );
  }
  return (
    <section className={'new-broadcast-page'}>
      {showConfirmModal &&
        <ConfirmBroadcastModal
          closeModal={closeConfirmModal}
          confirmModal={confirmBroadcast}
          broadcast={broadcast}
          recipients={recipients}
          variantAssignments={variantAssignments}
        />
      }
      { attachmentModalOpen && <AttachmentModal close={closeAttachmentModal} setAttachment={setAttachment} /> }
      { batchSelectionModalOpen && <BatchSelectionModal close={closeBatchModal} batchOptions={batchOptions} setBatchOptions={setBatchOptions} /> }
      { campaignSelectionModalOpen && 
          <CampaignSelectionModal
            close={closeCampaignSelectionModal}
            campaigns={campaignOptions}
            selectedCampaign={campaignId}
            setCampaign={setCampaignId}
          />
      }
      { scheduleModalOpen && 
          <ScheduleSelectionModal
            close={closeScheduleModal}
            nextBroadcastDate={nextBroadcastDate} 
            setNextBroadcastDate={setNextBroadcastDate} 
          />
      }
      <section className={'broadcast-container'}>
        <section className={'broadcast-header-container'}>
          <p className={'broadcast-title'}>{'Broadcast campaign'}</p>
          { !!error && <span className={'new-broadcast-error-text'}>{error}</span> }
        </section>
        <section className={'broadcast-detail-container'}>
          <section className={'broadcast-field-container'}>
            <span className={'broadcast-field-label'}>{'Sending to:'}</span>
            <section className={'recipients-text'}>{recipientsText}</section>
            { /** <button className={'view-recipients-button'} onClick={viewOrEdit}>{'View/Edit'}</button> */ }
          </section>
          <section className={'broadcast-field-container'}>
            <section className={'broadcast-field-subtitle'}>
              <span className={'broadcast-field-label'}>{'Write a quick message:'}</span>
            </section>
            <TemplateTextInput
              editable={true}
              text={message}
              setText={updateMessage}
              placeholder={'Write your message here which will be sent out to recipients...'}
              shouldUseVariables={true}
              additionalClassNames={['section-message-template-input', 'new-broadcast-message']}
              showCharCount={true}
              maxCharCount={maxCharCount}
              welcomeSection={true}
              initialYOffset={10}
              yOffset={25}
              xOffset={-10}
              additionalButtons={additionalButtons}
              campaignName={campaignName}
              removeCampaign={removeCampaign}
            />
            { attachment && campaignId === null  && 
                <Attachment attachment={attachment} deleteAttachment={() => setAttachment(null)} /> 
            }
          </section>
          <section className={'broadcast-field-container'}>
            <section className={'broadcast-field-subtitle'}>
              <span className={'broadcast-field-label'}>{'Select message channel:'}</span>
            </section>
            <ChannelSelectionOptions
              availableOptions={campaign ? campaign.channels : AVAILABLE_CHANNEL_LIST}
              selectedChannels={channel ? [channel] : []}
              setSelectedChannels={setChannel}
              showTitle={false}
              viewOnly={false}
              hideTooltips={true}
              isSingleSelect={true}
            />
          </section>
        </section>
        <section className={'broadcast-field-container no-margin'}>
          <section className={'broadcast-field-checkbox'}>
            <input className={'checkbox'} type={'checkbox'} checked={includeStopMessage} onChange={() => setIncludeStopMessage(!includeStopMessage)} />
            <label className={'checkbox-label'}>{'Include stop message'}</label>
          </section>
        </section>
      </section>
      <section className={'submit-buttons'}>
        <button className={`preview-button ${previewDisabled ? 'disabled' : ''}`} onClick={openConfirmModal} disabled={previewDisabled}>
          {'Preview'}
        </button>
        <button className={'schedule-button'} onClick={openScheduleModal}>
          {'Schedule send'}
        </button>
        <button className={'more-options-button'} onClick={openBatchModal}>
          {'Batch send'}
        </button>
      </section>
    </section>
  )
}

NewBroadcastPage.displayName = 'NewBroadcastPage';
NewBroadcastPage.propTypes = propTypes;
export default NewBroadcastPage;