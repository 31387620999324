import { FlatfileListener } from '@flatfile/listener';
import api from '@flatfile/api';
import { bulkRecordHook } from '@flatfile/plugin-record-hook';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import dayjs from 'dayjs';
import { submitRecipientRows, postManyGroups, getGroupsList, postSyncCustomers, postBulkInteractions } from '../../../../../apis/Api';
import { DATE_REGEX, MILLISECONDS_IN_A_YEAR, MOBILE_REGEX } from '../../../../../Constants';
import { splitDates } from '../../../../../utils/DataUtils';

const BATCH_SIZE = 50;

dayjs.extend(customParseFormat);

export const listener = FlatfileListener.create(async (listener) => {
  const employeesToSubmit = [];
  const customersToSubmit = [];
  const pageCount = 1;
  const today = Date.now();

  listener.on(
    'job:ready',
    { job: 'workbook:syncInteractions' },
    async ({ context: { jobId, workbookId }, payload }) => {
      const { data: workbookSheets } = await api.sheets.list({ workbookId });
      const sheets = [];
      let dataRecords = [];
      let sheetId;
      for (const [_, element] of workbookSheets.entries()) {
        sheetId = element.id;
        const { data: records } = await api.records.get(element.id);
        dataRecords = records;
        records.records.forEach(record => sheets.push(record));
      }


      const customers = sheets.map(({ id, values }) => {
        return {
          id,
          firstName: values.firstName.value,
          lastName: values.lastName.value,
          emailAddress: values.emailAddress.value,
          mobileNumber: values.mobileNumber.value,
          referenceId: values.referenceId.value,
          recipientId: values.recipientId.value,
        }
      });

      try {
        await api.jobs.ack(jobId, {
          info: 'Starting job to sync interactions with database.',
          progress: 0,
        });

        const response = await postSyncCustomers(customers);
        const { syncedCustomers } = response;
        let numUpdatedRows = 0;
        const updatedRecords = dataRecords.records.map(record => {
          const customer = syncedCustomers.find(customer => customer.id === record.id);
          if (customer) {
            numUpdatedRows += 1;
            record.values.recipientId.value = customer.recipientId;
          }
          return record;
        });
        await api.records.update(sheetId, updatedRecords);

        await api.jobs.complete(jobId, {
          outcome: {
            message: `Successfully synced ${numUpdatedRows} rows with database. Check out recipient id column for successfully synced fields.`,
          },
        }); 
      }
      catch (err) {
        console.error(`database[error]: ${err}`);
        await api.jobs.fail(jobId, {
          outcome: {
            message:
              'Failed to sync interactions. Please try again later.',
          },
        });
      }
    }
  );

  listener.on(
    'job:ready',
    { job: 'workbook:submitInteractions' },
    async ({ context: { jobId, workbookId }, payload }) => {
      const { data: workbookSheets } = await api.sheets.list({ workbookId });
      const sheets = [];
      let sheetId;
      let dataRecords = [];
      for (const [_, element] of workbookSheets.entries()) {
        sheetId = element.id;
        const { data: records } = await api.records.get(element.id);
        dataRecords = records;
        records.records.forEach(record => {
          sheets.push(record);
        });
      }


      const interactions = sheets.map(({ id, values }) => {
        return {
          id,
          interactionType: values.interactionType.value,
          description: values.description.value ? values.description.value : '',
          date: dayjs(values.date.value, 'DD/MM/YYYY').toDate().getTime(),
          transactionValue: parseFloat(values.transactionValue.value),
          transactionCurrency: values.transactionCurrency.value,
          referenceId: values.referenceId.value,
          recipientId: values.recipientId.value,
          done: values.done.value,
        };
      })
        .filter(({ done, recipientId }) => done !== true && recipientId);
      try {
        await api.jobs.ack(jobId, {
          info: 'Starting job to sync interactions with database.',
          progress: 0,
        });
        console.log({ interactions });

        const response = await postBulkInteractions(interactions);

        let numUpdatedRows = 0;
        if (response.success) {
          const updatedRecords = dataRecords.records.map(record => {
            const customer = interactions.find(row => row.id === record.id);
            if (customer) {
              numUpdatedRows += 1;
              record.values.done.value = true;
            }
            return record;
          });
          await api.records.update(sheetId, updatedRecords);
        }

        await api.jobs.complete(jobId, {
          outcome: {
            message: `Successfully submitted ${numUpdatedRows} rows to the database. Check out done column for successfully submitted rows.`,
          },
        }); 
      }
      catch (err) {
        console.error(`database[error]: ${err}`);
        await api.jobs.fail(jobId, {
          outcome: {
            message:
              'Failed to sync interactions. Please try again later.',
          },
        });
      }
    }
  );

  listener.on(
    'job:ready',
    { job: 'workbook:submitCustomers' },
    async ({ context: { jobId, workbookId }, payload }) => {
      const groupsResponse = await getGroupsList();
      let groups = groupsResponse.success ? groupsResponse.groups : [];
      const { data: workbookSheets } = await api.sheets.list({ workbookId });

      const sheets = [];
      for (const [_, element] of workbookSheets.entries()) {
        const { data: records } = await api.records.get(element.id);
        records.records.forEach(record => sheets.push(record));
      }

      // generate customers where groups is just a name.
      const customers = await Promise.all(sheets.map(async ({ values }) => {
        const dayMonthYear = values.formattedBirthday.value ? splitDates(values.formattedBirthday.value) : null;
        const groupValue = values.group.value ? values.group.value : 'DEFAULT_GROUP';

        return {
          firstName: values.firstName.value,
          lastName: values.lastName.value,
          emailAddress: values.emailAddress.value,
          mobileNumber: values.mobileNumber.value,
          groups: groupValue,
          address: values.address.value,
          source: values.source.value,
          favouriteBrands: values.favouriteBrands.value,
          deliverability: values.deliverability.value,
          formattedBirthday: values.formattedBirthday.value,
          customerSegmentCategory: values.customerSegmentCategory.value,
          birthday: dayMonthYear && dayMonthYear.length === 3 ? new Date(dayMonthYear[2], parseInt(dayMonthYear[1]) - 1, dayMonthYear[0]).getTime() : null,
          isNew: true,
          managers: [],
          status: 'Active',
        }
      }));
      // generate list of all groups that don't exist.
      const newGroups = [ ...new Set(
        customers
          .filter(customer => groups.find(group => group.name.toLowerCase() !== customer.groups.toLowerCase()))
          .map(customerWithNewGroup => customerWithNewGroup.groups)
      )];

      // Create the new groups and save the new full group list.
      const newGroupsResponse = await postManyGroups(newGroups);
      groups = newGroupsResponse.success ? newGroupsResponse.groups : groups;

      // Map groupId to new customers
      const customersWithGroupIds = customers.map(customer => {
        const groupId = groups.find(group => group.name.toLowerCase() === customer.groups.toLowerCase())._id;
        return { ...customer, groups: [groupId] };
      })

      try {

        await api.jobs.ack(jobId, {
          info: 'Starting job to submit customers to database.',
          progress: 0,
        });

        const batchCount = Math.ceil(customersWithGroupIds.length / BATCH_SIZE);
        const responses = {};
        for (let i = 0; i < batchCount; i++) {
          const from = i * BATCH_SIZE;
          const to = (i + 1) * BATCH_SIZE;
          responses[i] = await submitRecipientRows(employeesToSubmit, customersWithGroupIds.slice(from, to), pageCount);
          await api.jobs.ack(jobId, {
            info: 'Starting job to submit customers to database.',
            progress: (100 / batchCount) * (i + 1),
          });
        }
        const failedBatches = Object.keys(responses).filter(responseKey => !responses[responseKey].success);
        if (failedBatches.length === 0) {
          await api.jobs.complete(jobId, {
            outcome: {
              message: `Data successfully inserted into database.`,
            },
          });
        } else {
          throw new Error('Failed to submit data to database.');
        }
      } catch (error) {
        console.error(`database[error]: ${error}`);

        await api.jobs.fail(jobId, {
          outcome: {
            message:
              'Failed to create customers. Please try again later.',
          },
        });
      }
    }
  );

  listener.on(
    'job:ready',
    { job: 'workbook:submitEmployees' },
    async ({ context: { jobId, workbookId }, payload }) => {
      const groupsResponse = await getGroupsList();
      let groups = groupsResponse.success ? groupsResponse.groups : [];
      const { data: workbookSheets } = await api.sheets.list({ workbookId });

      const sheets = [];
      for (const [_, element] of workbookSheets.entries()) {
        const { data: records } = await api.records.get(element.id);
        records.records.forEach(record => sheets.push(record));
      }
      const employees = await Promise.all(sheets.map(async ({ values }) => {
        const dayMonthYear = values.formattedBirthday.value ? splitDates(values.formattedBirthday.value) : null;
        const groupValue = values.group.value ? values.group.value : 'DEFAULT_GROUP';

        const birthdayDateTime = new Date(dayMonthYear[2], parseInt(dayMonthYear[1]) - 1, dayMonthYear[0]).getTime();
        const isMinor = ((today - birthdayDateTime) / MILLISECONDS_IN_A_YEAR) < 18;
        
        return {
          firstName: values.firstName.value,
          lastName: values.lastName.value,
          emailAddress: values.emailAddress.value,
          mobileNumber: values.mobileNumber.value,
          groups: groupValue,
          deliverability: isMinor ? 'Opted out' : values.deliverability.value,
          roleType: values.roleType.value,
          formattedBirthday: values.formattedBirthday.value,
          birthday: dayMonthYear && dayMonthYear.length === 3 ? new Date(dayMonthYear[2], parseInt(dayMonthYear[1]) - 1, dayMonthYear[0]).getTime() : null,
          isNew: true,
          managers: [],
          status: 'Active',
          userAccess: 'NONE',
          onboarding: 'Onboard'
        }
      }));

      // generate list of all groups that don't exist.
      const newGroups = [ ...new Set(
        employees
          .filter(employee => groups.find(group => group.name.toLowerCase() !== employee.groups.toLowerCase()))
          .map(employeeWithNewGroup => employeeWithNewGroup.groups)
      )];

      // Create the new groups and save the new full group list.
      const newGroupsResponse = await postManyGroups(newGroups);
      groups = newGroupsResponse.success ? newGroupsResponse.groups : groups;

      // Map groupId to new employees
      const employeesWithGroupIds = employees.map(employee => {
        const groupId = groups.find(group => group.name.toLowerCase() === employee.groups.toLowerCase())._id;
        return { ...employee, groups: [groupId] };
      })

      try {
        await api.jobs.ack(jobId, {
          info: 'Starting job to submit employees to database.',
          progress: 0,
        });

        const batchCount = Math.ceil(employeesWithGroupIds.length / BATCH_SIZE);
        const responses = {};
        for (let i = 0; i < batchCount; i++) {
          const from = i * BATCH_SIZE;
          const to = (i + 1) * BATCH_SIZE;
          responses[i] = await submitRecipientRows(employeesWithGroupIds.slice(from, to), customersToSubmit, pageCount);
          await api.jobs.ack(jobId, {
            info: 'Starting job to submit employees to database.',
            progress: (100 / batchCount) * (i + 1),
          });
        }
        const failedBatches = Object.keys(responses).filter(responseKey => !responses[responseKey].success);
        if (failedBatches.length === 0) {
          await api.jobs.complete(jobId, {
            outcome: {
              message: `Data successfully inserted into database.`,
            },
          });

        } else {
          throw new Error('Failed to submit data to database.');
        }
      } catch (error) {
        console.error(`database[error]: ${error}`);

        await api.jobs.fail(jobId, {
          outcome: {
            message:
              'Failed to create employees. Please try again later.',
          },
        });
      }
    }
  );

  listener.use(
    bulkRecordHook('customer_import', (records) => {
      return records.map(record => {
        record.compute(
          'deliverability',
          (deliverability, record) => deliverability ? deliverability : 'Subscribed',
          'Deliverability is auto set to Subscribed unless manually set.'
        );
        const formattedBirthday = record.get('formattedBirthday');
        if (formattedBirthday && !DATE_REGEX.test(formattedBirthday)) {
          record.addError('formattedBirthday', 'Please enter a valid date in format DD/MM/YYYY');
        }
        const mobileNumber = record.get('mobileNumber');
        if (mobileNumber && !MOBILE_REGEX.test(mobileNumber)) {
          record.addError('mobileNumber', 'Please enter a valid mobile number e.g. 61xxxxxxxxx')
        }
        return record;
      })
    })
  );

  listener.use(
    bulkRecordHook('employee_import', (records) => {
      return records.map(record => {
        record.compute(
          'deliverability',
          (deliverability, record) => deliverability ? deliverability : 'Subscribed',
          'Deliverability is auto set to Subscribed unless manually set.'
        );
        const formattedBirthday = record.get('formattedBirthday');
        if (formattedBirthday && !DATE_REGEX.test(formattedBirthday)) {
          record.addError('formattedBirthday', 'Please enter a valid date in format DD/MM/YYYY');
        }
        const mobileNumber = record.get('mobileNumber');
        if (mobileNumber && !MOBILE_REGEX.test(mobileNumber)) {
          record.addError('mobileNumber', 'Please enter a valid mobile number e.g. 61xxxxxxxxx')
        }
        return record;
      })
    })
  );
});
