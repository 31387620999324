export const ASSET_CDN_URL = 'https://dq55dzprwc7f6.cloudfront.net';

export const DATE_REGEX = new RegExp([
  /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|/,      // DAY
  /^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|/,  // MONTH
  /^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/                                 // YEAR
].map(function (r) { return r.source; }).join(''));

export const MOBILE_REGEX = /^(\+61|61|0)?[2,3,4,7,8]{1}[0-9]{8}$/;

export const nonProdFlatFileAuth = {
  environmentId: 'us_env_OxRFAwKN',
  publishableKey: 'pk_c72fc8db01c643c3a6eac4dc5cb06f14'
}

export const prodFlatFileAuth = {
  environmentId: 'us_env_KMEVFY0N',
  publishableKey: 'pk_0ae11e0be7d242c0aa64c80193689b67'
}

export const MILLISECONDS_IN_A_YEAR = 365 * 24 * 60 * 60 * 1000;
export const MILLISECONDS_IN_A_DAY = 24 * 60 * 60 * 1000;