import {
  stringEquals,
  stringNotEquals,
  numberEquals,
  numberNotEquals,
  isLessThan,
  isMoreThan,
  contains,
  isOneOf,
  notContains,
  isEmpty,
  isNotEmpty,
  inMonth,
  arrayContains,
  arrayNotContains,
  dateEquals,
  dateNotEquals,
} from './FilterHelpers';

export const VALUE_TYPES = {
  DROPDOWN: 'DROPDOWN',
  STRING: 'STRING',
  NUMBER: 'NUMBER',
  DATE: 'DATE',
  ARRAY: 'ARRAY'
}

const { DROPDOWN, STRING, DATE, ARRAY, NUMBER } = VALUE_TYPES;

export const FILTER_FIELDS = {
  ATTRIBUTE_FIELD: 'attribute',
  CONDITION_FIELD: 'condition',
  VALUE_FIELD: 'value'
}

export const MONTHS = {
  'JAN': {
    key: 'JAN',
    displayText: 'January',
    value: 0,
  },
  'FEB': {
    key: 'FEB',
    displayText: 'February',
    value: 1,
  },
  'MAR': {
    key: 'MAR',
    displayText: 'March',
    value: 2,
  },
  'APR': {
    key: 'APR',
    displayText: 'April',
    value: 3,
  },
  'MAY': {
    key: 'MAY',
    displayText: 'May',
    value: 4,
  },
  'JUN': {
    key: 'JUN',
    displayText: 'June',
    value: 5,
  },
  'JUL': {
    key: 'JUL',
    displayText: 'July',
    value: 6,
  },
  'AUG': {
    key: 'AUG',
    displayText: 'August',
    value: 7,
  },
  'SEP': {
    key: 'SEP',
    displayText: 'September',
    value: 8,
  },
  'OCT': {
    key: 'OCT',
    displayText: 'October',
    value: 9,
  },
  'NOV': {
    key: 'NOV',
    displayText: 'November',
    value: 10,
  },
  'DEC': {
    key: 'DEC',
    displayText: 'December',
    value: 11,
  },
};

export const CONTACT_TYPE = {
  'CUSTOMER': {
    key: 'CUSTOMER',
    displayText: 'Customer',
  },
  'EMPLOYEE': {
    key: 'EMPLOYEE',
    displayText: 'Employee',
  },
};

export const FILTER_ATTRIBUTES = {
  firstName: {
    key: 'firstName',
    displayText: 'First Name',
    type: STRING
  },
  lastName: {
    key: 'lastName',
    displayText: 'Last Name',
    type: STRING
  },
  mobileNumber: {
    key: 'mobileNumber',
    displayText: 'Mobile',
    type: STRING
  },
  emailAddress: {
    key: 'emailAddress',
    displayText: 'Email',
    type: STRING
  },
  // role: {
  //   key: 'role',
  //   displayText: 'Role',
  //   type: STRING
  // },
  groups: {
    key: 'groups',
    displayText: 'Group',
    type: ARRAY
  },
  birthday: {
    key: 'birthday',
    displayText: 'Birthday',
    type: DATE
  },
  lastInteractionDate: {
    key: 'lastInteractionDate',
    displayText: 'Last Interaction Date',
    type: DATE
  },
  lastAppointmentDate: {
    key: 'lastAppointmentDate',
    displayText: 'Last Appointment Date',
    type: DATE
  },
  lastCampaignDate: {
    key: 'lastCampaignDate',
    displayText: 'Last Campaign Date',
    type: DATE
  },
  lastTransactionDate: {
    key: 'lastTransactionDate',
    displayText: 'Last Transaction Date',
    type: DATE
  },
  countInteractions: {
    key: 'countInteractions',
    displayText: 'Number of Interactions',
    type: NUMBER
  },
  countAppointments: {
    key: 'countAppointments',
    displayText: 'Number of Appointments',
    type: NUMBER
  },
  countTransactions: {
    key: 'countTransactions',
    displayText: 'Number of Transactions',
    type: NUMBER
  },
  contactType: {
    key: 'contactType',
    displayText: 'Contact Type',
    type: DROPDOWN
  },
  lifeTimeValue: {
    key: 'lifeTimeValue',
    displayText: 'Lifetime Value',
    type: NUMBER
  },
  customerSegmentCategory: {
    key: 'customerSegmentCategory',
    displayText: 'Customer Segment',
    type: STRING
  },
}

export const FILTER_CONDITIONS = {
  [DROPDOWN]: {
    inMonth: {
      key: 'isOneOf',
      displayText: 'Is One Of',
      valueType: DROPDOWN,
      valueTypeTitle: 'Select Type',
      valueTypeValues: CONTACT_TYPE,
      filterFunction: isOneOf
    }
  },
  [DATE]: {
    inMonth: {
      key: 'inMonth',
      displayText: 'In this month',
      valueType: DROPDOWN,
      valueTypeTitle: 'Select Month',
      valueTypeValues: MONTHS,
      filterFunction: inMonth
    },
    equal: {
      key: 'equal',
      displayText: 'Equal to',
      valueType: DATE,
      valueTypeTitle: 'equal',
      filterFunction: dateEquals
    },
    notEqual: {
      key: 'notEqual',
      displayText: 'Not Equal to',
      valueType: DATE,
      valueTypeTitle: 'notEqual',
      filterFunction: dateNotEquals
    },
    isLessThan: {
      key: 'isLessThan',
      displayText: 'Is Before',
      valueType: DATE,
      valueTypeTitle: 'isLessThan',
      filterFunction: isLessThan
    },
    isMoreThan: {
      key: 'isMoreThan',
      displayText: 'Is After',
      valueType: DATE,
      valueTypeTitle: 'isMoreThan',
      filterFunction: isMoreThan
    },
    empty: {
      key: 'empty',
      displayText: 'Is empty',
      valueType: DATE,
      valueTypeTitle: 'empty',
      filterFunction: isEmpty
    },
    notEmpty: {
      key: 'notEmpty',
      displayText: 'Is not empty',
      valueType: DATE,
      valueTypeTitle: 'notEmpty',
      filterFunction: isNotEmpty
    },
  },
  [ARRAY]: {
    contains: {
      key: 'contains',
      displayText: 'Contains',
      valueType: ARRAY,
      valueTypeTitle: 'contains',
      filterFunction: arrayContains
    },
    notContains: {
      key: 'notContains',
      displayText: 'Does not contain',
      valueType: ARRAY,
      valueTypeTitle: 'notContains',
      filterFunction: arrayNotContains
    },
  },
  [NUMBER]: {
    equal: {
      key: 'equal',
      displayText: 'Equal to',
      valueType: NUMBER,
      valueTypeTitle: 'equal',
      filterFunction: numberEquals
    },
    notEqual: {
      key: 'notEqual',
      displayText: 'Not Equal to',
      valueType: NUMBER,
      valueTypeTitle: 'notEqual',
      filterFunction: numberNotEquals
    },
    isLessThan: {
      key: 'isLessThan',
      displayText: 'Is Less Than',
      valueType: NUMBER,
      valueTypeTitle: 'isLessThan',
      filterFunction: isLessThan
    },
    isMoreThan: {
      key: 'isMoreThan',
      displayText: 'Is More Than',
      valueType: NUMBER,
      valueTypeTitle: 'isMoreThan',
      filterFunction: isMoreThan
    },
    empty: {
      key: 'empty',
      displayText: 'Is empty',
      valueType: NUMBER,
      valueTypeTitle: 'empty',
      filterFunction: isEmpty
    },
    notEmpty: {
      key: 'notEmpty',
      displayText: 'Is not empty',
      valueType: NUMBER,
      valueTypeTitle: 'notEmpty',
      filterFunction: isNotEmpty
    },
  },
  [STRING]: {
    equal: {
      key: 'equal',
      displayText: 'Equal to',
      valueType: STRING,
      valueTypeTitle: 'equal',
      filterFunction: stringEquals
    },
    notEqual: {
      key: 'notEqual',
      displayText: 'Not Equal to',
      valueType: STRING,
      valueTypeTitle: 'notEqual',
      filterFunction: stringNotEquals
    },
    contains: {
      key: 'contains',
      displayText: 'Contains',
      valueType: STRING,
      valueTypeTitle: 'contains',
      filterFunction: contains
    },
    notContains: {
      key: 'notContains',
      displayText: 'Does not contain',
      valueType: STRING,
      valueTypeTitle: 'notContains',
      filterFunction: notContains
    },
    empty: {
      key: 'empty',
      displayText: 'Is empty',
      valueType: STRING,
      valueTypeTitle: 'empty',
      filterFunction: isEmpty
    },
    notEmpty: {
      key: 'notEmpty',
      displayText: 'Is not empty',
      valueType: STRING,
      valueTypeTitle: 'notEmpty',
      filterFunction: isNotEmpty
    },
  }
}