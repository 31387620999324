import { filterArrayOfObjByFieldValue, filterArrayOfObjByFieldValueArray, getYearsBetweenDates, sortArrayOfObjByDateFn } from "./DataUtils";
import { INTERACTION_TYPE_MAPPING } from "../workrex/components/managers/communications/MessagingConstants";
import { MILLISECONDS_IN_A_DAY } from "../Constants";

const { APPOINTMENT, TRANSACTION, MEETING } = INTERACTION_TYPE_MAPPING;

/**
 * Takes in a user object and checks if there are managers. If there are,
 * return the firstName of the first manager in the list.
 * @param {String} managerNameComponent enum of one of the following: 'firstName', 'lastName', 'fullName'
 * @param {Object} user User Object
 * @returns 
 */
export const computeEmployeeManagerName = (managerNameComponent) => (user) => {
  // Check managers field exists and has values.
  if (!user || !user.managers || user.managers.length === 0) return '';
  const firstManager = user.managers[0];
  // Check found manager is not some string (should be an object).
  if (typeof firstManager === 'string') return '';

  // Return name component.
  if (managerNameComponent === 'fullName') {
    return `${firstManager.firstName} ${firstManager.lastName}`;
  }
  return firstManager[managerNameComponent];
};

/************************ INTERACTIONS DEPENDENT FORMULA FUNCTIONS ************************/
/**
 * Returns the latest interaction by startDateTime.
 * @param {*} user User Object
 * @returns 
 */
export const computeLastInteractionDate = (user) => {
  if (!user || !user.interactions || user.interactions.length === 0) return null;
  const interactions = user.interactions.sort(sortArrayOfObjByDateFn('startDateTime')('desc'));
  if (interactions.length > 0) {
    return interactions[0].startDateTime
      ? interactions[0].startDateTime
      : '';
  }
  return null;
};

/**
 * Returns the first or last interaction date.
 * @param {String} transactionOrder Enum of ['first', 'last'] which decides the sort order.
 * @param {Object} user User Object
 * @returns 
 */
export const computeFirstOrLastTransactionDate = (transactionOrder) => (user) => {
  if (!user || !user.interactions || user.interactions.length === 0) return null;
  const sortOrder = transactionOrder === 'first' ? 'asc' : 'desc';
  const transactions = user.interactions
    .filter(filterArrayOfObjByFieldValue('type')(TRANSACTION.key))
    .sort(sortArrayOfObjByDateFn('startDateTime')(sortOrder));
  if (transactions.length > 0) {
    return transactions[0].startDateTime
      ? transactions[0].startDateTime
      : '';
  }
  return null;
};

/**
 * Returns the lifetime total value of all transactions of a user.
 * @param {*} user User Object
 * @returns 
 */
export const computeLifetimeValue = (user) => {
  if (!user || !user.interactions || user.interactions.length === 0) return 0;
  const transactions = user.interactions
    .filter(filterArrayOfObjByFieldValue('type')(TRANSACTION.key));
  if (transactions.length > 0) {
    return transactions.reduce((lifetimeValue, transaction) => transaction.transactionValue ? lifetimeValue + transaction.transactionValue : lifetimeValue, 0);
  }
  return 0;
};

/**
 * Returns the average transaction value per transaction of a user.
 * @param {*} user User Object
 * @returns 
 */
export const computeAverageTransactionValue = (user) => {
  if (!user || !user.interactions || user.interactions.length === 0) return 0;
  const transactions = user.interactions
    .filter(filterArrayOfObjByFieldValue('type')(TRANSACTION.key));
  if (transactions.length > 0) {
    const lifetimeValue = transactions.reduce((lifetimeValue, transaction) => transaction.transactionValue ? lifetimeValue + transaction.transactionValue : lifetimeValue, 0)
    return lifetimeValue / transactions.length;
  }
  return 0;
};

/**
 * Returns the average purchase frequency of a user.
 * @param {*} user User Object
 * @returns 
 */
export const computeAveragePurchaseFrequency = (user) => {
  if (!user || !user.interactions || user.interactions.length === 0) return 0;
  const transactions = user.interactions
    .filter(filterArrayOfObjByFieldValue('type')(TRANSACTION.key))
    .sort(sortArrayOfObjByDateFn('startDateTime')('asc'));
  if (transactions.length > 0) {
    const firstTransactionDate = transactions[0].startDateTime;
    const today = Date.now();
    const daysBetweenFirstAndLastTransaction = (today - firstTransactionDate) / MILLISECONDS_IN_A_DAY;
    return Math.ceil(daysBetweenFirstAndLastTransaction / transactions.length);
  }
  return 0;
};

/**
 * Returns the average purchase frequency of a user.
 * @param {*} user User Object
 * @returns 
 */
export const computeYearsActive = (user) => {
  if (!user || !user.interactions || user.interactions.length === 0) return 0;
  const interactions = user.interactions
    .filter(filterArrayOfObjByFieldValueArray('type')([TRANSACTION.key, APPOINTMENT.key, MEETING.key]))
    .sort(sortArrayOfObjByDateFn('startDateTime')('asc'));
  if (interactions.length > 0) {
    const firstInteraction = interactions[0].startDateTime;
    const today = Date.now();
    return getYearsBetweenDates(firstInteraction, today);
  }
  return 0;
};

/**
 * Returns the age of a user.
 * @param {*} user User Object
 * @returns 
 */
export const computeAge = (user) => {
  if (!user || !user.birthday) return null;
  const today = Date.now();
  return getYearsBetweenDates(user.birthday, today);
};

/**
 * Returns the latest interaction of APPOINTMENT type by startDateTime.
 * @param {*} user User Object
 * @returns 
 */
export const computeLastAppointmentDate = (user) => {
  if (!user || !user.interactions || user.interactions.length === 0) return null;
  const appointments = user.interactions
    .filter(filterArrayOfObjByFieldValue('type')(APPOINTMENT.key))
    .sort(sortArrayOfObjByDateFn('startDateTime')('desc'));
  if (appointments.length > 0) {
    return appointments[0].startDateTime
      ? appointments[0].startDateTime
      : '';
  }
  return null;
};

/**
 * Returns the latest broadcast ordered by lastSendDate.
 * @param {*} user User Object
 * @returns 
 */
export const computeLastCampaignDate = (user) => {
  if (!user || !user.broadcasts || user.broadcasts.length === 0) return null;
  const broadcasts = user.broadcasts
    .filter(({ lastSendDate }) => lastSendDate)
    .sort(sortArrayOfObjByDateFn('lastSendDate')('desc'));
  if (broadcasts.length > 0) {
    return broadcasts[0].lastSendDate
      ? broadcasts[0].lastSendDate
      : '';
  }
  return null;
};

/**
 * Returns the number of interactions of a certain type against a user.
 * @param {Array} interactionTypes List of interaction types to filter by before getting the count.
 * @param {*} user User Object
 * @returns 
 */
export const computeInteractionTypeCount = (interactionTypes) => (user) => {
  if (!user || !user.interactions || user.interactions.length === 0) return 0;
  return user.interactions
    .filter(filterArrayOfObjByFieldValueArray('type')(interactionTypes))
    .length;
};

export const getFirstValueFromArray = (array) => array[0];

export const getNestedValue = (fieldName) => (obj) => obj[fieldName];

export const filterByFieldName = (fieldName) => (filterFunc) => (fieldValues) => (array) => {
  return array.filter(filterFunc(fieldName)(fieldValues))
}